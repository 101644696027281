import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style5">
        <div className="inner">
            <header className="major">
                <h1>Vincent DUCOS</h1>
                <h2>Photographe professionnel</h2>
            </header>
            <div className="content align-right">
                <h3><i>"Photographier, c'est une attitude, une façon d'être, une manière de vivre"<br/>Henri Cartier-Bresson</i></h3>
            </div>
        </div>
    </section>
)

export default BannerLanding
