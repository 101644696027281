import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import Biographie from "../components/Biographie";

import pic_1 from '../assets/images/pic_galerie_1.jpg';
import pic_2 from "../assets/images/pic_galerie_2.jpg";
import pic_3 from "../assets/images/pic_galerie_3.jpg";
import pic_4 from "../assets/images/pic_galerie_4.jpg";
import pic_5 from "../assets/images/pic_galerie_5.jpg";
import pic_6 from "../assets/images/pic_galerie_6.jpg";
import pic_7 from '../assets/images/pic_galerie_7.jpg';
import pic_8 from '../assets/images/pic_galerie_8.jpg'
import pic_9 from '../assets/images/pic_galerie_9.jpg'
import pic_10 from '../assets/images/pic_galerie_10.jpg'
import pic_11 from '../assets/images/pic_galerie_11.jpg'
import pic_12 from '../assets/images/pic_galerie_12.jpg'
import pic_13 from '../assets/images/pic_galerie_13.jpg'
import pic_14 from '../assets/images/pic_galerie_14.jpg'
import pic_15 from '../assets/images/pic_galerie_15.jpg'
import pic_16 from '../assets/images/pic_galerie_16.jpg'
import pic_17 from '../assets/images/pic_galerie_17.jpg'
import pic_18 from '../assets/images/pic_galerie_18.jpg'


const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Vincent DUCOS photographe galerie</title>
            <meta name="description" content="galerie de photos Poitiers" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Portfolio</h2>
                    </header>

                </div>
            </section>
            <section id="one">
                <div className="inner">
                    <div className="box alt">
                        <div className="grid-wrapper">

                            <div className="col-6"><span className="image fit"><img src={pic_1} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_16} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_3} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_5} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_6} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_7} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_4} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_9} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_2} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_11} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_17} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_10} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_15} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_8} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_13} alt="" /></span></div>
                            <div className="col-6"><span className="image fit"><img src={pic_14} alt="" /></span></div>
                        </div>
                    </div>
                </div>
            </section>
            <Biographie/>
        </div>

    </Layout>
)

export default Landing